import React, { FC, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks/reduxHooks'

interface DynamicTextAutoProps {
  textWrapper: any
}

const DynamicTextAuto: FC<DynamicTextAutoProps> = ({ textWrapper }) => {
  if (textWrapper)
    return (
      <div className={`text mt-2`}>
        {textWrapper?.title && (
          <p className='t3 mb-0'>
            <strong>{textWrapper?.title}</strong>
          </p>
        )}
        {textWrapper?.descriptionList?.map((el: any, idx: number) => (
          <small key={idx}>{el}</small>
        ))}
      </div>
    )

  return <></>
}
const CreateAutoText = () => {
  const { dynamicTexts } = useAppSelector(state => state?.main)

  return (
    <section className='mb-4 text-wrapper'>
      {dynamicTexts?.exchangeRules?.descriptionList && dynamicTexts.exchangeRules?.descriptionList.map((el: any, idx: number) => <small key={idx}>{el}</small>)}
      {dynamicTexts?.mainRules?.title && (
        <p className='t3 mt-3 mb-2'>
          <strong>{dynamicTexts?.mainRules?.title}</strong>
        </p>
      )}
      {dynamicTexts?.mainRules?.descriptionList && (
        <>
          {dynamicTexts?.mainRules?.descriptionList &&
            dynamicTexts?.mainRules?.descriptionList.map((el: any, idx: number) => (
              <small className='d-block mb-1' key={idx}>
                {el}
              </small>
            ))}
        </>
      )}

      <DynamicTextAuto textWrapper={dynamicTexts?.forbidden} />
      <DynamicTextAuto textWrapper={dynamicTexts?.infoSteps} />

      <DynamicTextAuto textWrapper={dynamicTexts?.payment} />
      <DynamicTextAuto textWrapper={dynamicTexts?.paymentAccepted} />
      <DynamicTextAuto textWrapper={dynamicTexts?.orderCompleted} />
      <DynamicTextAuto textWrapper={dynamicTexts?.attention} />
    </section>
  )
}

export default CreateAutoText
