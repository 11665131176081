import { useEffect, useMemo, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import Footer from './features/footer'
import Header from './features/header'
import { useAppDispatch, useAppSelector } from './hooks/reduxHooks'
import useTimerDown from './hooks/useTimer'
import { authPages, pages } from './pages'
import { fetchGetFrom } from './store/actions/tradeActions'
import { fetchRefresh } from './store/actions/userActions'
import { setNav } from './store/reducers/mainReducer'
import ScrollToTop from './components/hocs/scroll-to-top'
import { path } from './utils/consts'
import useApiLk from './hooks/useApiLk'
import { fetchRefreshApiLkJWTTokens } from './store/actions/apiLkAuthActions'
import Cookie from './components/Cookie'
import { Modal } from './components/Modal/Modal'
import FingerprintJS from 'fingerprintjs2'
import { Base64 } from 'js-base64'
import { sha256 } from 'js-sha256'
import { setFingerprint } from './store/reducers/apiLkAuthReducer'
import 'react-toastify/dist/ReactToastify.css'
import {fetchSiteSettings} from "./store/actions/bidActions";

const getFingerprint = (): Promise<FingerprintJS.Component[]> =>
  new Promise(resolve => {
    FingerprintJS.get((components: any) => {
      resolve(components)
    })
  })

function App() {
  const [browserFingerprint, setBrowserFingerprint] = useState<string>('')
  const [ipData, setIpData] = useState('')
  const [showReport, setShowReport] = useState(true)
  const dispatch = useAppDispatch()

  const { isAuthApi, fingerprint } = useAppSelector(state => state.apiLkAuth)

  useEffect(() => {
    if (showReport) {
      getFingerprint().then(frprint => {
        setBrowserFingerprint(sha256(JSON.stringify(frprint)))
        setIpData('')
        setShowReport(false)
      })
    }
  }, [showReport])

  useEffect(() => {
    if (isAuth){
      dispatch(fetchRefresh())
      setInterval(() => {
        dispatch(fetchRefresh())
      }, 600000)
    }
  }, []);

  useEffect(() => {
    dispatch(fetchSiteSettings())
    setInterval(() => {
      dispatch(fetchSiteSettings())
    }, 300000)
  }, []);

  useEffect(() => {
    if (browserFingerprint) {
      const encodeData = Base64.encode(JSON.stringify({ remote_ip: ipData, visitor_id: browserFingerprint }))

      dispatch(setFingerprint(encodeData))
    }
  }, [dispatch, browserFingerprint, ipData, fingerprint])

  const [openCookies, setOpenCookies] = useState(false)

  const { isAuth } = useAppSelector(state => state.user)

  const { timeLeft } = useTimerDown(200)
  const location = useLocation()

  const { isLK, isAuthLk } = useApiLk()

  useEffect(() => {
    dispatch(fetchGetFrom())
  }, [])
  useEffect(() => {
    // if (isAuth) dispatch(fetchRefresh())
  }, [isAuth])

  useEffect(() => {
    if (isAuthApi && fingerprint) dispatch(fetchRefreshApiLkJWTTokens())
  }, [isAuthApi, fingerprint])

  useEffect(() => {
    dispatch(setNav(false))
  }, [location.pathname])
  useEffect(() => {
    if (
      location.pathname === path.ABOUT_US ||
      location.pathname === path.CONTESTS ||
      location.pathname === path.FAQ ||
      location.pathname === path.TERMS ||
      location.pathname === path.MAIN
    )
      document.body.style.background = `url('/images/static/about_bg.png') no-repeat top / 100%`
    else document.body.style.background = ``
  }, [location.pathname])

  useEffect(() => {
    if (isAuth) {
      // if (timeLeft === 0) dispatch(fetchRefresh())
    }
  }, [timeLeft, isAuth])

  useEffect(() => {
    if (isAuthApi) {
      if (timeLeft === 0) dispatch(fetchRefreshApiLkJWTTokens())
    }
  }, [timeLeft, isAuthApi])

  const AuthPages: any = useMemo(() => {
    if (isAuth !== undefined) {
      return authPages(isAuth, isAuthApi)
    }
  }, [isAuth, isAuthApi])
  useEffect(() => {
    const cookiesModal = localStorage.getItem('cookiesModal')
    if (!cookiesModal) setOpenCookies(true)
  }, [])

  const closeCookie = () => {
    localStorage.setItem('cookiesModal', 'accepted')
    setOpenCookies(false)
  }

  useEffect(() => {
    const source = document.referrer
    const domain = source.replace(/^(?:https?:\/\/)?(?:[^@\/\n]+@)?(?:www\.)?([^:\/\n]+).*/, '$1')
    if (domain) sessionStorage.setItem('utm_source', domain)
  }, [])

  return (
    <div className='App'>
      <div className='wrapper'>
        {isAuthLk && <Header />}
        {openCookies && <Cookie openCookies={openCookies} closeCookie={closeCookie} />}
        <ScrollToTop>
          <Routes>
            {
              // isAuth ?
              //     AuthPages.map((el: any, idx: number) => (
              //         <Route key={idx} path={el.path} element={el.element} />
              //     )) :
              //     pages.map((el, idx) => (
              //         <Route key={idx} path={el.path} element={el.element} />
              //     ))
              AuthPages.map((el: any, idx: number) => (
                <Route key={idx} path={el.path} element={el.element} />
              ))
            }
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        </ScrollToTop>
        {/* Modal window */}
        <Modal />
      </div>
      {!isLK && <Footer />}
    </div>
  )
}

export default App
