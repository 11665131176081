import React, { useEffect } from 'react'
import { useLocation, useNavigate, useMatch, useSearchParams, useParams } from 'react-router-dom'
import InputTrade from '../../components/inputs/input-trade'
import { useAppDispatch, useAppSelector } from '../../hooks/reduxHooks'
import useGetText from '../../hooks/useGetText'
import { fetchGetCourse } from '../../store/actions/tradeActions'
import { setLang } from '../../store/reducers/mainReducer'
import { setCity, setFilteredCity, setFrom, setFromValue, setTo, setToValue } from '../../store/reducers/tradeReducer'
import { path } from '../../utils/consts'
import TradeForm from './trade-form'

function Trade() {
  const { directions, from, to, city, filter } = useAppSelector(state => state.trade)

  const dispatch = useAppDispatch()

  const { mainPageTexts } = useGetText()

  const [searchParams] = useSearchParams()

  const navigate = useNavigate()
  const location = useLocation()

  // match URL pattern for SEO (/exchange/:from/:to/:city)
  const match = useMatch(path.EXCHANGE_SEO)
  const matchCity = useMatch(path.EXCHANGE_CITY_SEO)

  function initialStateDirection() {
    let initialFrom: string | null, initialTo: string | null, initialCity: string | null

    initialFrom = searchParams.get('cur_from')
    initialTo = searchParams.get('cur_to')
    initialCity = searchParams.get('city')

    initialFrom = initialFrom === 'USDTTRC20' ? 'USDTTRC' : initialFrom === 'USDTERC20' ? 'USDTERC' : initialFrom
    initialTo = initialTo === 'USDTTRC20' ? 'USDTTRC' : initialTo === 'USDTERC20' ? 'USDTERC' : initialTo

    dispatch(setFrom(initialFrom || from || 'SBERRUB'))
    dispatch(setTo(initialTo || to || 'BTC'))
    dispatch(setCity(initialCity?.toUpperCase() || city || 'MSK'))

    dispatch(setFrom(from || 'SBERRUB'))
    dispatch(setTo(to || 'BTC'))
    dispatch(setCity(city || 'MSK'))

    dispatch(setFilteredCity())
  }

  useEffect(() => {
    if (directions?.length > 0) {
      setTimeout(() => {
        dispatch(fetchGetCourse())
      }, 0)
    }
  }, [from, to, city, directions])

  useEffect(() => {
    dispatch(setFilteredCity())
  }, [from, to])

  useEffect(() => {
    initialStateDirection()
  }, [filter, directions])

  useEffect(() => {
    if (location.pathname.includes(path.EXCHANGE)) {
      const rid = searchParams.get('rid')
      const utm_source = searchParams.get('utm_source')
      const utm_medium = searchParams.get('utm_medium')

      dispatch(setFromValue(''))
      dispatch(setToValue(''))

      if (location.pathname.includes('en')) dispatch(setLang('en'))
      else if (location.pathname.includes('en')) dispatch(setLang('ru'))

      if (match?.params.from !== from || match?.params.to !== to || matchCity?.params.city !== city) {
        if (city) {
          navigate(`/exchange/${from}/${to}/${city}`)
        }
      }

      if (rid) localStorage.setItem('ref_id', rid)
      if (utm_source) sessionStorage.setItem('utm_source', utm_source)
        if (utm_medium) sessionStorage.setItem('utm_medium', utm_medium)

      let cur_from = searchParams.get('cur_from')
      let cur_to = searchParams.get('cur_to')
      const cur_city = searchParams.get('city')

      cur_from = cur_from === 'USDTTRC20' ? 'USDTTRC' : cur_from === 'USDTERC20' ? 'USDTERC' : cur_from
      cur_to = cur_to === 'USDTTRC20' ? 'USDTTRC' : cur_to === 'USDTERC20' ? 'USDTERC' : cur_to

      if (cur_from && cur_to && cur_city) {
        localStorage.setItem('from', cur_from)
        dispatch(setFrom(cur_from.toUpperCase()))

        localStorage.setItem('to', cur_to)
        dispatch(setTo(cur_to.toUpperCase()))

        localStorage.setItem('city', cur_city.toLowerCase())
        dispatch(setCity(cur_city.toLowerCase()))
      }

      localStorage.setItem('from', from)
      localStorage.setItem('to', to)
      localStorage.setItem('city', city && city.toLowerCase())
    }
  }, [from, to, city])

  return (
    <div className='trade'>
      <div className='row'>
        <div className='col-lg-6'>
          <InputTrade directions={directions} type='from' label={mainPageTexts?.LABEL_EXCHANGE_FROM} />
        </div>
        <div className='col-lg-6'>
          <InputTrade directions={directions} type='to' label={mainPageTexts?.LABEL_EXCHANGE_TO} reverseBtn={true} />
        </div>
      </div>
      <TradeForm />
    </div>
  )
}

export default Trade
