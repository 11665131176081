import { createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import TradeService from '../../service/trade-service'
import User from '../../service/user-service'
import { Category, CodeCategory, cookiesName } from '../../utils/consts'
import utils from '../../utils/utils'
import store, { RootState } from '../store'
import Cookies from 'cookies-ts'
const cookies = new Cookies()

export type AppState = ReturnType<typeof store.getState>

export const fetchGetFrom = createAsyncThunk('bid/fetchGetFrom', async () => {
  try {
    const res = await TradeService.getFrom()
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data,
        status: res?.response?.status
      }
    } else {
      return res.data
    }
  } catch (err) {}
})

export const fetchGetFromHandler = (builder: any) => {
  builder.addCase(fetchGetFrom.pending, (state: any, action: PayloadAction<any>) => {})
  builder.addCase(fetchGetFrom.fulfilled, (state: any, action: PayloadAction<any>) => {
    const content = action.payload.content
    const filter = content?.filter.map((el: any) => {
      const to = el.to.map((item: any) => ({
        ...item,
        category: CodeCategory[item.code]
      }))
      const from = { ...el.from, category: CodeCategory[el.from.code] }
      return {
        from,
        to
      }
    })
    state.directions = content?.directions
    state.filter = filter
    state.city_arr = content?.city_arr.map((el: any) => ({
      name_rus: el.title_rus,
      name_en: el.title_eng,
      code: el.code,
      fromUsd: el?.is_accept_usd,
      fromRub: el?.is_accept_rub,
      fromAed: el?.is_accept_aed,
      fromTry: el?.is_accept_try,
      fromEur: el?.is_accept_eur,
      fromAmd: el?.is_accept_amd,
      fromKzt: el?.is_accept_kzt,
      fromGel: el?.is_accept_gel,
      toRub: el?.is_give_rub,
      toUsd: el?.is_give_usd,
      toAed: el?.is_give_aed,
      toTry: el?.is_give_try,
      toEur: el?.is_give_eur,
      toAmd: el?.is_give_amd,
      toKzt: el?.is_give_kzt,
      toGel: el?.is_give_gel,
      main: el?.main
    }))
  })
  builder.addCase(fetchGetFrom.rejected, (state: any, action: PayloadAction<any>) => {})
}

export const fetchGetCourse = createAsyncThunk('bid/fetchGetCourse', async (_, api) => {
  try {
    cookies.remove(cookiesName.BID_STEP)
    cookies.remove(cookiesName.BID_PROCESSING)
    cookies.remove(cookiesName.STEP_VERIFICATION)
    cookies.remove(cookiesName.CARD_HASH)
    const state = api.getState() as RootState
    const { from, to, city, city_arr } = state?.trade
    if (from === undefined && to === undefined) return
    if (!state.trade.directions?.some((el: any) => el?.code === from) || !state.trade.directions?.some((el: any) => el?.code === to)) return
    const data = {
      currency_from: from,
      currency_to: to,
      is_auto: !utils.conditionCash(from, to),
      city: utils.conditionCash(from, to) ? city : ''
    }
    const res = await TradeService.getCourse(data)
    if (res?.response?.status >= 400) {
      return {
        msg: res.response.data,
        status: res?.response?.status
      }
    } else {
      return res.data
    }
  } catch (err) {}
})

export const fetchGetCourseHandler = (builder: any) => {
  builder.addCase(fetchGetCourse.pending, (state: any, action: PayloadAction<any>) => {
    state.courseLoader = true
    state.bid_processing_message = ''
    state.status = ''
  })
  builder.addCase(fetchGetCourse.fulfilled, (state: any, action: PayloadAction<any>) => {
    if (action?.payload?.hasOwnProperty('msg') && action?.payload.msg?.code == 404) {
      state.status = 'error'
    }

    try {
      const { course_from, course_to, max_exchange_from, max_exchange_to, min_exchange_from, min_exchange_to, rate } = action.payload?.content
      // const rate = (Number(course_give) === 1) ? 1 / course_get : course_give;

      state.course = {
        from: course_from,
        maxFrom: max_exchange_from,
        maxTo: max_exchange_to,
        minFrom: min_exchange_from,
        minTo: min_exchange_to,
        to: course_to
      }
      state.status = 'succes'
      state.rate = rate
      state.courseLoader = false
    } catch (e) {
      state.courseLoader = false
    }
    if (!state.lock_get) {
      if (state.valueFrom !== '') {
        state.valueTo = utils.fromValueFormating(+state.valueFrom, 'to', state)
      } else {
        state.valueTo = state.valueFrom
      }
    } else {
      if (state.valueTo !== '') {
        state.valueFrom = utils.fromValueFormating(+state.valueTo, 'from', state, 'reverce')
      } else {
        state.valueFrom = state.valueTo
      }
    }
  })
  builder.addCase(fetchGetCourse.rejected, (state: any, action: PayloadAction<any>) => {})
}

// валидация кашелька

export const fetchValidatePurse = createAsyncThunk('trade/fetchValidatePurse', async function (data: any, { rejectWithValue, getState }) {
  try {
    const { refer, symbol } = data
    const res = await TradeService.validatePurse(data)
    return { ...res.data, symbol }
  } catch (err) {}
})

export const fetchValidatePurseHandler = (builder: any) => {
  builder.addCase(fetchValidatePurse.pending, (state: any, action: PayloadAction<any>) => {})
  builder.addCase(fetchValidatePurse.fulfilled, (state: any, action: PayloadAction<any>) => {
    if (action.payload?.symbol === 'CARD') {
      if (action.payload?.content?.valid) {
        state.isValidPurseCard = {
          ...state.isValidPurseCard,
          card: true,
          message: ''
        }
      } else {
        state.isValidPurseCard = {
          ...state.isValidPurseCard,
          card: false,
          message: 'Неверные данные'
        }
      }
    } else {
      if (action.payload?.content?.valid) {
        state.isValidPurseWallet = {
          ...state.isValidPurseWallet,
          wallet: true,
          message: ''
        }
      } else {
        state.isValidPurseWallet = {
          ...state.isValidPurseWallet,
          wallet: false,
          message: 'Неверные данные'
        }
      }
    }
  })
  builder.addCase(fetchValidatePurse.rejected, (state: any, action: PayloadAction<any>) => {})
}

// создание заявки
export const fetchCreateBid = createAsyncThunk('trade/fetchCreateBid', async function (dataGet: any, { getState }) {
  function getLenguage(lange: string): string {
    switch (lange) {
      case 'ru':
        return 'ru'
      case 'en':
        return 'eng'
      default:
        return 'ru'
    }
  }
  try {
    const { email, tel, tg, wallet, name, card, rid = 0, sbp_bank, dest_tag, cards_number }: any = dataGet
    const { from, to, city, valueFrom, lock_get, valueTo } = (getState() as RootState).trade
    const { language } = (getState() as RootState).main

    const purse = wallet || card
    const cash = CodeCategory[from] === Category.CASH || CodeCategory[to] === Category.CASH

    const data = {
      dest_tag: dest_tag || '',
      currency_from: from,
      currency_to: to,
      cash,
      amount_from: Number(valueFrom),
      amount_to: Number(valueTo),
      client_email: 'example@example.com',
      client_name: name || '',
      client_phone: tel || '',
      ref_id: Number(localStorage.getItem('ref_id')) || 0,
      utm_source: sessionStorage.getItem('utm_source') || '',
      utm_medium: sessionStorage.getItem('utm_medium') || '',
      receiver_address: purse?.split(' ').join('') || '',
      city_from: city.toLocaleLowerCase(),
      sbp_bank,
      lock_to: lock_get,
      cards_number: (cards_number ? cards_number.trimEnd() : '') || card?.replace(/ /g, ''),
      tg: tg || '',
      language: getLenguage(language)
    }

    const res = await TradeService.createBid(data)

    if (res?.status >= 400) {
      return {
        msg: res.data,
        status: res?.status
      }
    } else {
      return res.data
    }
  } catch (err) {
    console.error(err)
  }
})

export const fetchCreateBidHandler = (builder: any) => {
  builder.addCase(fetchCreateBid.pending, (state: any) => {
    state.setSent = true
    state.status = 'pending'
    cookies.remove(cookiesName.PROCESSING)
  })
  builder.addCase(fetchCreateBid.fulfilled, (state: any, action: PayloadAction<any>) => {
    state.setSent = false
    if (action.payload?.status >= 400) {
      state.message = 'Неверные данные'
    } else {
      if (action.payload?.success === true) {
        state.hashId = action?.payload?.content?.hash
        state.excTrc = action?.payload?.content?.needExtr
        state.status = 'success'
      }
      if (action.payload?.success === false) {
        if (action.payload?.message === 'Wrong amount') {
          state.message = 'Неверная сумма'
          state.status = 'error'
        }
      }
    }
  })
  builder.addCase(fetchCreateBid.rejected, (state: any, action: PayloadAction<any>) => {})
}
